<template>
	<div></div>
</template>

<script>
export default {
	name: 'SRFCloseout',
	data() {
		return {
			loaded: false,
		};
	},
	created() {
		this.loaded = false;
		this.$http.get(`srf/${this.$route.params.id}/closeout`).then(resp => {
			this.data = resp.data.data;
			this.loaded = true;
		});
	},
};
</script>

<style></style>
